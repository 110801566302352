import React, { useContext } from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ImgWithOverlay from "../components/img-with-overlay";
import HeaderContext from "../contexts/HeaderContext";
import MarTech from "../components/MarTech";

function IndexPage({ data }) {
  const headerAttr = useContext(HeaderContext);

  let p2 = props => <p className="mt-8">{props.children}</p>;

  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title=""
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <MarTech />

      <section>
        <ImgWithOverlay
          filename="adult-brainstorming-briefing.jpg"
          height={`calc(100vh - ${headerAttr.height}px)`}
          minHeight={500}
          maxHeight={700}
          backgroundColor="#1464f6dd"
        >
          <div className="text-white sm:flex-row flex-col flex py-6 h-full max-w-5xl mx-auto">
            <div className="sm:w-1/2 w-full p-4 my-auto">
              <h1 className="text-2xl sm:text-4xl lg:text-5xl sm:text-left text-center">
                Gortek develops software for startup founders and agile
                business leaders
              </h1>
            </div>
            <div className="sm:w-1/2 w-full p-4 my-auto h-full flex apps-image" />
          </div>
        </ImgWithOverlay>
      </section>

      <section className="mt-6 py-10 px-6 flex md:flex-row flex-col">
        <div className="md:w-1/2 w-full px-6">
          <h2 className="text-3xl">
            We specialize in building <em>Apps</em>
          </h2>
          {/* TODO: Use this phrase: "Progressive Web App Development Services" */}
          <p className="mt-4">
            You've heard the phrase, <em>"There's an app for that."</em> Have you ever
            thought of a useful app idea?
          </p>

          <h3 className="mt-4 text-base">Gortek can turn that idea into reality.</h3>
          
          <p className="mt-4">
            With the right validation and execution, it's possible your idea
            could be the product of a successful business that changes the
            trajectory of your life.
          </p>
          <p className="mt-4">
            It's not easy though. Developing an app is a complex endeavor that
            most people misjudge. Software is hard. Starting a business is hard.
            {/* Gortek strives to reduce the risk of starting a software business */}
            {/* for first-time, non-technical entrepreneurs. */}
          </p>
          <p className="mt-4">
            Gortek is striving to perfect the process of validating app ideas,
            finding product-market fit, and enabling first-time, non-technical
            entrepreneurs to lead and grow a profitable business. Not every idea
            will work out, but working with our evolving process is the fastest,
            least expensive way to find out.
          </p>
          <p className="mt-4">
            Working with us to develop your app makes the journey as easy as
            possible.
          </p>
          <p className="text-right mt-3">
            <Link to="/services/app-startups" className="text-primary text-lg">
              Learn more about startup app
              development&nbsp;&rsaquo;&nbsp;&rsaquo;
            </Link>
          </p>
        </div>

        <div className="md:w-1/2 w-full px-6 mt-16 md:mt-0">
          <h2 className="text-3xl">Custom Software Development, APIs &amp; Cloud Services</h2>
          <p className="mt-4">
            Most apps can't do much without "the cloud". We architect and
            develop custom APIs to do the heavy lifting and security sensitive
            aspects of an application's logic.
          </p>
          <p className="mt-4">
            Careful analysis of your requirements determine which approach and
            technologies make the most sense:
          </p>
          <ul
            style={{ listStyleType: "disc", listStylePosition: "inside" }}
            className="m-4"
          >
            <li>3rd party API integration</li>
            <li>Custom APIs using REST, GraphQL, gRPC</li>
            <li>micro-service architecture</li>
            <li>"Serverless" and managed services</li>
            <li>Containers, Docker, and Kubernetes</li>
            <li>Custom data processing pipelines</li>
            <li>
              Leverage appropriate storage formats and indexing technologies
            </li>
            <li>Hybrid database solutions</li>
          </ul>
          <p className="mt-4">
            Gortek delivers reliable solutions to complex problems. Our focus is
            developing web applications, APIs, and the cloud infrastructure that
            powers them.
          </p>
          <p className="text-right mt-3">
            <Link
              to="/services/custom-software-development"
              className="text-primary text-lg"
            >
              Learn more about custom software
              development&nbsp;&rsaquo;&nbsp;&rsaquo;
            </Link>
          </p>
        </div>
      </section>

      {/*       <section className="mt-8"> */}
      {/*         <h3 className="text-primary mt-3">Work We've Done</h3> */}
      {/*         <div className="flex md:flex-row flex-col"> */}
      {/*           <Link */}
      {/*             to="/services/startups" */}
      {/*             className="md:w-1/3 m-3 p-3 shadow rounded bg-white" */}
      {/*           > */}
      {/*             <h6 className="font-bold">Utility App</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We build your idea */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*  */}
      {/*           <Link */}
      {/*             to="/services/small-businesses" */}
      {/*             className="md:w-1/3 m-3 p-3 shadow rounded bg-white" */}
      {/*           > */}
      {/*             <h6 className="font-bold">Community App</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We help you grow */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*  */}
      {/*           <Link className="md:w-1/3 m-3 p-3 shadow rounded bg-white" to="/"> */}
      {/*             <h6 className="font-bold">E-Commerce</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We coach you */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*         </div> */}
      {/*       </section> */}

      {/*       <section className="mt-8"> */}
      {/*         <h3 className="text-primary mt-3">A little about the company</h3> */}
      {/*         <div className="flex md:flex-row flex-col"> */}
      {/*           <Link */}
      {/*             to="/services/startups" */}
      {/*             className="md:w-1/3 m-3 p-3 shadow rounded bg-white" */}
      {/*           > */}
      {/*             <h6 className="font-bold">Utility App</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We build your idea */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*            */}
      {/*           <Link */}
      {/*             to="/services/small-businesses" */}
      {/*             className="md:w-1/3 m-3 p-3 shadow rounded bg-white" */}
      {/*           > */}
      {/*             <h6 className="font-bold">Community App</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We help you grow */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*  */}
      {/*           <Link className="md:w-1/3 m-3 p-3 shadow rounded bg-white" to="/"> */}
      {/*             <h6 className="font-bold">E-Commerce</h6> */}
      {/*             <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*               We coach you */}
      {/*             </p> */}
      {/*           </Link> */}
      {/*         </div> */}
      {/*       </section> */}

      {/*       <section className="mt-20 flex flex-wrap"> */}
      {/*         <h3 className="text-primary mt-20 w-full flex-shrink-0">Lorem Ipsum</h3> */}
      {/*         <div className="md:w-1/2 w-full flex flex-col"> */}
      {/*           <div className="flex"> */}
      {/*             <Link to="/services/startups" className="w-1/2 m-3 p-3"> */}
      {/*               <h6 className="font-bold">First</h6> */}
      {/*               <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*                 Description */}
      {/*               </p> */}
      {/*             </Link> */}
      {/*  */}
      {/*             <Link to="/services/small-businesses" className="w-1/2 m-3 p-3"> */}
      {/*               <h6 className="font-bold">Second</h6> */}
      {/*               <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*                 Description */}
      {/*               </p> */}
      {/*             </Link> */}
      {/*           </div> */}
      {/*  */}
      {/*           <div className="flex"> */}
      {/*             <Link to="/services/startups" className="w-1/2 m-3 p-3"> */}
      {/*               <h6 className="font-bold">Third</h6> */}
      {/*               <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*                 Description */}
      {/*               </p> */}
      {/*             </Link> */}
      {/*  */}
      {/*             <Link to="/services/small-businesses" className="w-1/2 m-3 p-3"> */}
      {/*               <h6 className="font-bold">Fourth</h6> */}
      {/*               <p className="text-sm text-gray-700 white-space-no-wrap truncate"> */}
      {/*                 Description */}
      {/*               </p> */}
      {/*             </Link> */}
      {/*           </div> */}
      {/*         </div> */}
      {/*       </section> */}
    </Layout>
  );
}

export default IndexPage;
